.btn {
  position: relative;
  font-weight: 700;
  color: $white;
  padding: $spacing-1 + $spacing-3;
  z-index: 0;
  flex-shrink: 0;
  border-radius: 100px;
  background-color: $primary;
  box-shadow: 0 3px 0 $primary-dark;
  &:active {
    box-shadow: none !important;
  }
}

.btn--none {
  box-shadow: none;
}

.btn--primary {
  background-color: $primary;
  box-shadow: 0 3px 0 $primary-dark;
}

.btn--google {
  background-color: $google;
  box-shadow: 0 3px 0 $google-dark;
}
.btn--facebook {
  background-color: $facebook;
  box-shadow: 0 3px 0 $facebook-dark;
}

.btn--tiktok {
  background-color: $tiktok-bg;
  box-shadow: 0 3px 0 $tiktok-1, 0 -3px 0 $tiktok-2;
}

.btn--tripAdvisor {
  background-color: $tripAdvisor;
  box-shadow: 0 3px 0 $tripAdvisor-dark;
  color: black;
}

.btn--instagram {
  background: $instagram-gradient;
  box-shadow: 0 3px 0 $instagram-dark;
}

.btn--disabled {
  opacity: 0.5;

  &:active {
    box-shadow: none !important;
  }
}

.btn--disabled-dark {
  background-color: #727d76;

  box-shadow: 0 3px 0 #515d56 !important;
}

.btn--small {
  padding: $spacing-2 $spacing-5 + $spacing-1 $spacing-2 $spacing-3;

  .btn__icon {
    right: $spacing-2;
  }
}

.btn__icon {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: $spacing-4;
}
