.custom-range,
.custom-range__content {
  border-radius: $radius-round;
  z-index: 1;
  overflow: hidden;
}

.custom-range__grade {
  position: relative;
  color: $primary;

  &::after,
  &::before {
    position: absolute;
    content: "";
  }
}

.custom-range__grade--active {
  color: $white;

  &::after {
    height: $spacing-6;
    width: $spacing-6;
    background: $primary;
    top: 0;
    bottom: 0;
    left: 50%;
    margin: auto;
    z-index: -1;
    border-radius: $radius-round;
    transform: translateX(-50%);
  }

  &::before {
    height: $spacing-6;
    width: 100vw;
    background: $primary;
    opacity: 0.2;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 50%;
    z-index: -1;
  }

  &:first-of-type {
    &::before {
      display: none;
    }
  }
}
