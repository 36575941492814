.winner {
  background: $white;
  border-radius: $radius-round;
}

.winner__avatar {
  flex-shrink: 0;
}

.winner__infos {
  overflow: hidden;
}

.winner__prize {
  font-size: 13px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.winner__time {
  font-size: 11px;
  line-height: 1;
}

.winner--inactive {
  opacity: 0.4;
}
