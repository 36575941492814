.step-navigation__icon {
  height: $spacing-2;
  width: $spacing-2;
  border-radius: $radius-round;
  background: $grey-300;
}

.step-navigation__icon--active {
  background: $primary;
  width: $spacing-3;
}

.step-navigation__step--appearing {
  transform: translateX(calc(100% + $spacing-3));
  animation: stepAppearing 0.3s ease-out forwards;
}

.step-navigation__step--disappearing {
  transform: translateX(0);
  animation: stepDisappearing 0.3s ease-out forwards;
}

@keyframes stepAppearing {
  from {
    transform: translateX(calc(100% + $spacing-3));
  }

  to {
    transform: translateX(0);
  }
}

@keyframes stepDisappearing {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - $spacing-3));
  }
}
