.layout {
  background: $primary-light;
  min-height: 100vh;
}

.desktop__logo {
  height: 50px;
}

.layout__content {
  padding: $spacing-3;
  flex: 1 0 0;
}

.layout__desktop {
  flex: 1 0 0;
}
