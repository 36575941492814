@import "src/scss/base/variable.scss";

$shift-result-0: -40px; // gift
$shift-result-1: -100px; // sun glasses
$shift-result-2: -165px; // heart
$shift-result-3: -230px; // smile

@keyframes spin {
  from {
    transform: translateY($shift-result-0);
  }

  to {
    transform: translateY($shift-result-3);
  }
}

.spin-game {
  position: relative;
  background: $primary-x-light;
  border-radius: $radius;
  height: 148px;
  overflow: hidden;
  z-index: 1;

  &::after,
  &::before {
    content: "";
    position: absolute;
    height: $spacing-3;
    width: $spacing-3;
    background: $primary;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(45deg);
  }

  &::before {
    right: -$spacing-2;
  }

  &::after {
    left: -$spacing-2;
  }
}

.spin-game-slot {
  height: 100%;
  border-radius: $radius-small;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;

  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 30%;
    width: 100%;
    left: 0;
    z-index: 1;
  }

  &::before {
    top: 0;
    background: linear-gradient(
      rgba(232, 207, 191, 0.5),
      rgba(232, 207, 191, 0)
    );
  }

  &::after {
    bottom: 0;
    background: linear-gradient(
      rgba(232, 207, 191, 0),
      rgba(232, 207, 191, 0.5)
    );
  }

  .spin-game-slot-spinning {
    animation-name: spin;
    animation-iteration-count: 4;
    animation-duration: 0.4s;
    animation-delay: 0s;
    animation-timing-function: linear;
  }

  .spin-game-slot-img {
    height: 64px;
    img {
      height: 60%;
    }
  }

  .spin-game-slot-result-0 {
    transform: translateY($shift-result-0);
  }

  .spin-game-slot-result-1 {
    transform: translateY($shift-result-1);
  }

  .spin-game-slot-result-2 {
    transform: translateY($shift-result-2);
  }

  .spin-game-slot-result-3 {
    transform: translateY($shift-result-3);
  }
}
