.footer {
  background: rgba($color: $white, $alpha: 0.25);
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.footer-link {
  color: rgba($color: $black, $alpha: 0.5);
  font-size: $fts-x-small;
  font-weight: 500;
}

.footer-link.footer-link-underlined {
  text-decoration: underline;
}

.footer-logo {
  padding-left: 5px;
  height: 14px;
  margin: 0;
}

.footer-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 25%;
}

.footer-box.footer-box-middle {
  justify-content: center;
  flex: 1 0 50%;
}
