.home {
  position: relative;
}

.home__game-container {
  flex: 1 0 100%;
}

.home__doodle {
  position: absolute;
  height: 140px;
  width: 140px;
  opacity: 0.15;
  z-index: 0;
}

.home__doodle--1 {
  top: 0;
  left: $spacing-3;
  transform: rotate(10deg);
}

.home__doodle--2 {
  top: 175px;
  right: -$spacing-4;
  transform: rotate(-15deg);
}
