.avatar {
  height: 36px;
  width: 36px;
  border-radius: $radius-round;
  position: relative;
  overflow: hidden;

  p {
    letter-spacing: 1px;
    z-index: 1;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($white, 0.4);
  }
}
