.expand-panel {
  padding: $spacing-1 + $spacing-2;
  border-top: 1px solid $grey-300;
  border-bottom: 1px solid $grey-300;

  & ~ .expand-panel {
    border-top: none;
  }
}

.expand-panel__content {
  margin-top: $spacing-1 + $spacing-2;
}
