.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #302925;
  color: white;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
}
