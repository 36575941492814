.input {
  position: relative;
  display: flex;
}

.input--raw {
  width: 100%;
  .input__input {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.input__label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  flex: 0 1 0;
}

.input__input {
  border-radius: $radius-small;
  box-shadow: 0 0 0 1px $grey-400;
  border: none;
  transition: box-shadow 0.1s ease-out;
  flex: 1 0 100%;
  appearance: none;
  outline: none;
  padding: $spacing-3;
  font-weight: 700;
  max-width: 100%;

  &:focus {
    box-shadow: 0 0 0 2px $grey-400;
  }
}

.input__icon {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  margin: auto;
}

.input--textarea {
  .input__input {
    resize: vertical;
  }
}

.input--select {
  .input__input {
    color: $black;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: $spacing-6;
    font-weight: 700;
  }

  .input__input--placeholder {
    color: $grey-600;
    font-weight: 500;
  }

  .input__icon {
    right: $spacing-2;
  }
}

.input--fake {
  .input__input {
    padding-left: $spacing-7;
    padding-right: $spacing-7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .input__icon {
    left: $spacing-3;
  }

  .input__arrow-up {
    background: $primary;
    clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
    height: 12px;
    width: 12px;
    right: $spacing-3;
    left: unset;
    transform: translateY(-7px);
  }

  .input__arrow-down {
    background: $primary;
    clip-path: polygon(50% 50%, 0 0, 100% 0);
    height: 12px;
    width: 12px;
    right: $spacing-3;
    left: unset;
    transform: translateY(7px);
  }
}

.checkbox-input__label {
  line-height: $spacing-4;
}

.checkbox-input__input {
  height: $spacing-4;
  width: $spacing-4;
  border: 1px solid $grey-400;
  border-radius: $radius-small;
  position: relative;
  appearance: none;
  flex-shrink: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: calc(100% - $spacing-2);
    width: calc(100% - $spacing-2);
    background: $primary;
    border-radius: $radius-x-small;
    transform: scale(0);
    transition: transform 0.1s ease-in-out;
  }

  &:checked {
    &::after {
      transform: scale(1);
    }
  }
}
