.desktop__bigTitle {
  font-size: 96px;
  line-height: 116px;
  text-shadow: 2px 2px 0px $primary-dark;
}

.desktop__lowTitle {
  font-size: 20px;
  line-height: 24px;
}

.desktop__container {
  max-width: 1440px;
  height: calc(100vh - 74px);
  margin: auto;
  position: relative;
  overflow: hidden;
}

.desktop__qrCodeContainer {
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  width: 407px;
  height: 454px;
  z-index: 1;
}

.desktop__qrCodeImg {
  border: 1px solid red;
  padding: 15px;
  border: 3px solid #0e3553;
  border-radius: 14.9297px;
}

.desktop__doodle {
  position: fixed;
  z-index: 0;
  opacity: 0.075;
}

.desktop__doodle--1 {
  left: 0%;
  right: 0%;
  top: -10%;
  transform: matrix(1, 0, 0, -1, 0, 0) scale(1.5);
  z-index: 10;
}

.desktop__doodle--2 {
  left: 15%;
  bottom: 0%;
}

.desktop__doodle--3 {
  right: 11.81%;
  bottom: -17%;
  transform: matrix(-1, 0, 0, 1, 0, 0) scale(0.5);
}

.desktop__doodle--4 {
  right: 27%;
  top: 18%;
  transform: rotate(-180deg);
}

.desktop__doodle--5 {
  top: -25%;
  right: 0%;
  transform: rotate(-180deg) scale(0.5);
}

.desktop__doodle--6 {
  top: -17%;
  right: -13%;
  transform: rotate(-15deg) scale(0.5);
}

.desktop__doodle--7 {
  transform: rotate(-180deg) scale(0.5);
  opacity: 0.025;
  top: 25%;
  left: 0%;
}
