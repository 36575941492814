$margin: 0, $spacing-1, $spacing-2, $spacing-3, $spacing-4, $spacing-5,
  $spacing-6, $spacing-7, $spacing-8, $spacing-9, $spacing-10;

@for $i from 1 through length($margin) {
  $size: #{nth($margin, $i)};
  $scale: #{$i - 1};

  .m-#{$scale} {
    margin: #{$size};
  }

  .p-#{$scale} {
    padding: #{$size};
  }

  .mt-#{$scale} {
    margin-top: #{$size};
  }

  .pt-#{$scale} {
    padding-top: #{$size};
  }

  .mb-#{$scale} {
    margin-bottom: #{$size};
  }

  .pb-#{$scale} {
    padding-bottom: #{$size};
  }

  .mr-#{$scale} {
    margin-right: #{$size};
  }

  .pr-#{$scale} {
    padding-right: #{$size};
  }

  .ml-#{$scale} {
    margin-left: #{$size};
  }

  .pl-#{$scale} {
    padding-left: #{$size};
  }
}
