/* Spacing */
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 16px;
$spacing-4: 24px;
$spacing-5: 32px;
$spacing-6: 40px;
$spacing-7: 48px;
$spacing-8: 56px;
$spacing-9: 64px;
$spacing-10: 72px;

/* Font familly */
$ftf-default: "Inter";
$ftf-heading: $ftf-default;

$facebook: #1877f2;
$facebook-dark: #1158b5;

/* Font Size */
$fts-xx-large: 32px;
$fts-x-large: 24px;
$fts-large: 18px;
$fts-default: 16px;
$fts-small: 14px;
$fts-x-small: 12px;

/* Default Colors */
$primary: var(--primary);
$primary-dark: var(--primary-dark);
$primary-light: var(--primary-light);
$primary-x-light: var(--primary-x-light);

/* Platform specific Colors */
$google: #4285f4;
$google-dark: #346bc6;

$instagram-gradient: linear-gradient(
  87.31deg,
  #f4b981 -2.51%,
  #e76157 38.13%,
  #9746b1 82.94%,
  #315ee2 112.22%
);
$instagram-dark: #8c4172;

$tiktok-1: #e9425a;
$tiktok-2: #6feeed;
$tiktok-bg: #000000;

$tripAdvisor: #24d997;
$tripAdvisor-dark: #1c996b;

$orange: #ff833d;
$blue: #413dff;
$green: #43ae45;
$red: #ed1f1f;
$red-dark: #7f1d1c;
$red-light: #fee2e1;

$custom-range: #bd5514;

$black: #0f0f0f;
$grey-900: #2b221d;
$grey-800: #2f2925;
$grey-700: #333333;
$grey-600: #5c5c5c;
$grey-500: #9d9d9d;
$grey-400: #d5d5d5;
$grey-300: #e8e8e8;
$grey-200: #f6f6f6;
$white: #fff;

/* Radius */
$radius: 16px;
$radius-small: 8px;
$radius-x-small: 4px;
$radius-round: 100px;

/* Shadows */
$shadow1: 0 0 12px rgba($primary, 0.12);
$shadow2: 0 4px 48px rgba($primary, 0.08);
@function shadow-btn($color) {
  @return 0 6px 0 $color;
}

/* Transition */
$timing-cubic: cubic-bezier(0.25, 0.1, 0.25, 1);
