// flex box
.f {
  display: flex;
}

.f-inline {
  display: inline-flex;
}

.f-direction-column {
  flex-direction: column;
}

.f-direction-row-reverse {
  flex-direction: row-reverse;
}

.f-center {
  align-items: center;
  justify-content: center;
}

.f-jc-center {
  justify-content: center;
}

.f-jc-start {
  justify-content: flex-start;
}

.f-jc-end {
  justify-content: flex-end;
}

.f-jc-space-b {
  justify-content: space-between;
}

.f-jc-space-a {
  justify-content: space-around;
}

.f-ai-center {
  align-items: center;
}

.f-ai-start {
  align-items: flex-start;
}

.f-ai-end {
  align-items: flex-end;
}
