@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
  }
  h1 {
    @apply text-4xl font-bold mb-4;
  }
  h2 {
    @apply text-3xl font-bold mb-3;
  }
  h3 {
    @apply text-2xl font-bold mb-2;
  }
  h4 {
    @apply text-xl font-bold mb-2;
  }
  h5 {
    @apply text-lg font-bold mb-2;
  }
  h6 {
    @apply text-base font-bold mb-2;
  }
}
