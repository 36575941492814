.icon {
  height: 24px;
  width: 24px;
}

.icon--loading {
  animation: rotate 1s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}
