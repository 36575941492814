:root {
  --primary: #ec6c19;
  --primary-x-light: #fff3eb;
  --primary-light: #ffd6bf;
  --primary-dark: #925025;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: $ftf-default, arial, sans-serif;
  font-size: $fts-default;
  line-height: 1.5;
  color: $black;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
blockquote,
caption,
details,
dl,
figure,
hgroup,
label,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
}
body,
figure,
html,
label,
textarea,
dd {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $ftf-heading, arial, sans-serif;
  margin: 0;
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;
}

table {
  margin-bottom: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
blockquote,
code,
img,
input,
pre,
table,
td,
textarea,
video {
  max-width: 100%;
}

img {
  height: auto;
}
div,
h1,
p,
table,
td,
textarea,
th {
  word-wrap: break-word;
  hyphens: none;
}

input,
select,
textarea {
  font-family: inherit;
  font-size: $fts-small;
  font-weight: 500;
  background: none;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: none;
  }
}

button {
  appearance: none;
  background: none;
  border: 0;
  font: inherit;
  cursor: pointer;
}

img {
  vertical-align: middle;
}

a img {
  border: 0;
}
/* scripts */
body > script {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s $timing-cubic;

  &:hover {
    text-decoration: inherit;
  }
}
ul {
  list-style: none;
}
nav {
  li {
    display: inline-block;
    vertical-align: middle;
  }
}

figure {
  img,
  div {
    margin-bottom: $spacing-1;
  }
}

iframe {
  border: 0;
  overflow: hidden;
}

.clearfix:after {
  content: " ";
  display: table;
  clear: both;
}

.clear {
  clear: both;
}

::placeholder {
  color: $grey-600;
  font-weight: 500;
}
