.carousel {
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    width: 80%;
    opacity: 0.4;
  }

  .swiper-slide-active {
    opacity: 1;
  }
}
