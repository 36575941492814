.collect__img {
  width: 130px;
  height: 130px;
  background: $primary-light;
  border-radius: $radius-round;
  padding: $spacing-4;
  margin: 0 auto $spacing-4 auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.collect__ticket {
  flex: 1 1 auto;
  border-radius: $radius;
}

.collect__ticket-top,
.collect__ticket-bottom {
  flex: 1 0 auto;
}

.collect__ticket-top {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: calc(100% + 2 * $spacing-5);
    border-bottom: $spacing-2 dashed $primary-light;
    bottom: 0;
    left: 0;
    margin-left: -$spacing-5;
    transform: translateY($spacing-1);
  }
}

.collect__ticket-bottom {
  position: relative;

  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 50px;
    width: 50px;
    background: $primary-light;
    top: -25px;
    border-radius: $radius-round;
  }

  &::after {
    right: -25px - $spacing-5;
  }

  &::before {
    left: -25px - $spacing-5;
  }
}

.collect__ticket-bottom--hidden {
  & > * {
    opacity: 0;
  }
}

.collect__remaining-time {
  color: $green !important;
}

.collect__remaining-time--expired {
  color: $red !important;
}

.collect__error_container {
  min-height: 25px;
}
