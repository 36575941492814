.modal {
  z-index: 1;
}

.modal__bg {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 2;
}

.modal__wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: $radius $radius 0 0;
  z-index: 3;
  max-height: 100%;
  overflow: scroll;
  background-color: white;
  padding: $spacing-3 $spacing-3 0 $spacing-3;
}

.modal__content {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.modal__title {
  margin-bottom: $spacing-3;
}

.modal__img {
  width: 175px;
  height: 175px;
  background: $primary-light;
  border-radius: $radius-round;
  padding: $spacing-4;
  margin: $spacing-3 auto $spacing-4 auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.modal__img__secondary {
  background: #f7f7f6;
}
.modal__img__newsletter {
  scale: 2.7;
  transform: rotate(15deg);
}

.modal__input {
  margin-top: $spacing-3;
  margin-bottom: $spacing-4;
}

.modal__btn {
  margin-top: $spacing-4;
  margin-bottom: $spacing-1;
}

.modal__btn--feedback {
  height: 56px;
  width: 72px;
  background: $grey-200;
  border-radius: $radius-round;
  font-size: 40px;
  line-height: 24px;
}

.modal__header {
  position: relative;
  border-bottom: 1px solid $grey-300;
  padding: $spacing-3;
  margin: (-$spacing-3) (-$spacing-3) 0 (-$spacing-3);
}

.modal__close {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: $spacing-3;
  height: 32px;
  width: 32px;
}

.modal--appearing {
  .modal__bg {
    opacity: 0;
    animation: bgAppearing 0.2s ease-in forwards;
  }

  .modal__wrapper {
    transform: translateY(100%);
    animation: modalAppearing 0.2s ease-out 0.2s forwards;
  }
}

.modal--disappearing {
  .modal__bg {
    opacity: 0.3;
    animation: bgDisppearing 0.2s ease-out 0.2s forwards;
  }

  .modal__wrapper {
    animation: modalDisppearing 0.2s ease-in forwards;
  }
}

.modal--center {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-3;

  .modal__wrapper {
    position: static;
    border-radius: $radius;
    padding: $spacing-4;
  }

  .modal__img {
    height: 75px;
    width: 75px;
    margin-top: $spacing-1;
  }

  .modal__btn {
    margin-bottom: $spacing-3;
  }

  &.modal--appearing {
    .modal__wrapper {
      transform: scale(0.8);
      opacity: 0;
      animation: modalCenterAppearing 0.2s ease-in 0.2s forwards;
    }
  }

  &.modal--disappearing {
    .modal__wrapper {
      transform: scale(1);
      opacity: 1;
      animation: modalCenterDisappearing 0.2s ease-out forwards;
    }
  }
}

.modal--winners,
.modal--pick-restaurant {
  position: relative;
}

.modal--center,
.modal--winners,
.modal--pick-restaurant {
  z-index: 3;
}

.modal-line-step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

@keyframes modalCenterAppearing {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalCenterDisappearing {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes bgAppearing {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.3;
  }
}

@keyframes bgDisppearing {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 0;
  }
}

@keyframes modalAppearing {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes modalDisppearing {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}
