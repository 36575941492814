.pick-restaurant {
  height: 85vh;
  margin: -$spacing-3;
}

.pick-restaurant__header {
  padding: $spacing-3;
  border-bottom: 1px solid $grey-300;
}

.pick-restaurant__input {
  width: 100%;
  .input__input {
    width: 100%;
  }
}

.pick-restaurant__restaurant {
  border-bottom: 1px solid $grey-300;
}
