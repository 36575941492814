h1,
.h1 {
  font-size: $fts-xx-large;
  line-height: 40px;
  color: $grey-900;
}

h2,
.h2 {
  font-size: $fts-x-large;
  line-height: 32px;

  color: $grey-800;
}

h3,
.h3 {
  font-size: $fts-large;
  line-height: 22px;
  font-size: 700;
}

.subtitle {
  font-size: $fts-default;
  font-weight: 500;
}

// Style helpers
.ft-up {
  text-transform: uppercase;
}

.ft-cap {
  text-transform: capitalize;
}

.ft-cap-first {
  text-transform: initial;
}

.ft-cap-first  {
  display: inline;
  text-transform: lowercase;
}

.ft-cap-first::first-letter {
  text-transform: capitalize;
}

.ft-center {
  text-align: center;
}

.ft-right {
  text-align: right;
}

.ft-left {
  text-align: left;
}

.ft-italic {
  font-style: italic;
}

.ft-nowrap {
  white-space: nowrap;
}

.ft-strikethrough {
  text-decoration: line-through;
}

.ft-truncat {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Size helpers
.fs-xx-large {
  font-size: xx-large;
}

.fs-x-large {
  font-size: x-large;
}

.fs-x-large {
  font-size: large;
}

.fs-small {
  font-size: $fts-small;
}

.fs-x-small {
  font-size: $fts-x-small;
}

// Weight helpers
.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}
