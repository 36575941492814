// Background helpers
.bg-white {
  background: $white;
}

.bg-primary-light {
  background: $primary-light;
}

.bg-grey-200 {
  background: $grey-200;
}

.bg-sparkles {
  background-image: url("../../../public/static/images/modals/sparkles.png");
  background-repeat: repeat;
  background-position: top center;
  background-size: auto;
}

// Colors helpers
.c-primary {
  color: $primary;
}

.c-primary-dark {
  color: $primary-dark;
}

.c-google {
  color: $google;
}

.c-tiktok-title {
  color: $tiktok-1;
}

.c-instagram {
  background-image: $instagram-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.c-trip-advisor {
  color: $tripAdvisor;
}

.c-grey-900 {
  color: $grey-900;
}

.c-grey-800 {
  color: $grey-800;
}

.c-grey-700 {
  color: $grey-700;
}

.c-grey-600 {
  color: $grey-600;
}

.c-grey-500 {
  color: $grey-500;
}

.c-grey-400 {
  color: $grey-400;
}

.c-grey-300 {
  color: $grey-300;
}

.c-grey-200 {
  color: $grey-200;
}

.c-white {
  color: $white;
}

.c-error {
  color: $red;
}
